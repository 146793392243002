//Local
import { A2VOTE_API } from "utils/config";
import { getAuthorization, getApiRoot, getA2Vote } from "utils/token";
import { handleResponse, handleCatch } from "utils/handleResponse";
//Reducer
import {
  LOAD_USER_LIST_END,
  LOAD_USER_LIST_SELECTED_END,
  RESET_USER_LIST_SELECTED,
  LOAD_USER_LIST_ACCEPT_END,
  USER_IS_ACCEPT,
  IS_AUTO_ACCEPT,
  LOAD_USER_LIST_SELECTED_START
} from "src/state/userList";

export const createUserList = (name, description, users, admins = [], secureGroups = []) => {
  return (dispatch) => {
    //dispatch({ type: CREATE_USER_START });
    const requestedOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: getAuthorization(),
        API_ROOT: getApiRoot(),
        A2VOTE: getA2Vote(),
      },
      body: JSON.stringify({
        name,
        description,
        users,
        admins,
        secureGroups
      }),
    };
    fetch(`${A2VOTE_API}/user-list`, requestedOptions)
      .then(handleResponse)
      .then((res) => {
        const { status, userLists } = res;
        if (status === 200) {
          dispatch({ type: LOAD_USER_LIST_END, payload: userLists });
        }
      })
      .catch(handleCatch);
  };
};

export const editUserList = (id, name, users, description = "", admins = [], secureGroups = []) => {
  return (dispatch) => {
    //dispatch({ type: CREATE_USER_START });
    const requestedOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: getAuthorization(),
        API_ROOT: getApiRoot(),
        A2VOTE: getA2Vote(),
      },
      body: JSON.stringify({
        id,
        name,
        description,
        users,
        admins,
        secureGroups
      }),
    };
    fetch(`${A2VOTE_API}/user-list`, requestedOptions)
      .then(handleResponse)
      .then((res) => {
        const { status, userLists } = res;
        if (status === 200) {
          dispatch({ type: LOAD_USER_LIST_END, payload: userLists });
        }
      })
      .catch(handleCatch);
  };
};

export const getAllUserList = () => {
  return (dispatch) => {
    //dispatch({ type: CREATE_USER_START });
    const requestedOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: getAuthorization(),
        API_ROOT: getApiRoot(),
      },
    };
    fetch(`${A2VOTE_API}/user-list`, requestedOptions)
      .then(handleResponse)
      .then((res) => {
        const { status, userLists } = res;
        dispatch({ type: LOAD_USER_LIST_END, payload: userLists });
      })
      .catch(handleCatch);
  };
};

export const resetUserList = () => {
  return (dispatch) => {
    dispatch({ type: RESET_USER_LIST_SELECTED });
  };
};

export const getUserFromKey = (key) => {
  return (dispatch) => {
    const requestedOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };
    fetch(`${A2VOTE_API}/user-list/key/${key}`, requestedOptions)
      .then(handleResponse)
      .then((res) => {
        const { status, userListUser } = res;
        const {userList, user} = userListUser;
        const linkValidationDate = userListUser.createdAt;
        dispatch({
          type: LOAD_USER_LIST_ACCEPT_END,
          payload: { userList, user, alreadyAccept: userListUser.isAccept, linkValidationDate },
        });
      })
      .catch(handleCatch);
  };
};

export const acceptList = (mail, password, username, phone, organization, pubKey) => {
  return (dispatch) => {
    const requestedOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        mail,
        password,
        username,
        phone,
        organization,
        API_ROOT: getApiRoot(),
      }),
    };
    fetch(`${A2VOTE_API}/user-list/${pubKey}`, requestedOptions)
      .then(handleResponse)
      .then((res) => {
        const { status, userListUser } = res;
        if (status === 200) {
          dispatch({ type: USER_IS_ACCEPT, payload: { isAccept: userListUser.isAccept } });
        }
      })
      .catch(handleCatch);
  };
};

export const autoAcceptList = (pubKey) => {
  return (dispatch) => {
    dispatch({ type: IS_AUTO_ACCEPT, payload: { isAutoAccept: true } });
    const requestedOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    };
    fetch(`${A2VOTE_API}/user-list/auto-accept/${pubKey}`, requestedOptions)
      .then(handleResponse)
      .then((res) => {
        const { status, userList } = res;
        dispatch({ type: USER_IS_ACCEPT, payload: { isAccept: true } });
      })
      .catch(handleCatch);
  };
};

export const getUserListFromId = (id) => {
  return (dispatch) => {
    dispatch({ type: LOAD_USER_LIST_SELECTED_START });
    const requestedOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: getAuthorization(),
        API_ROOT: getApiRoot(),
      },
    };
    fetch(`${A2VOTE_API}/user-list/${id}`, requestedOptions)
      .then(handleResponse)
      .then((res) => {
        const { status, userList } = res;
        const { userListUser } = userList;
        const newUser = [];
        userListUser?.map((user) => {
          newUser.push({mail: user?.user?.mail, isValidate: user?.user?.isValidate});
        });
        userList.users = newUser;
        dispatch({ type: LOAD_USER_LIST_SELECTED_END, payload: userList });
      })
      .catch(handleCatch);
  };
};

export const deleteUserList = (userListId) => {
  return (dispatch) => {
    //dispatch({ type: CREATE_USER_START });
    const requestedOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: getAuthorization(),
        API_ROOT: getApiRoot(),
      },
      body: JSON.stringify({
        id: userListId,
      }),
    };
    fetch(`${A2VOTE_API}/user-list`, requestedOptions)
      .then(handleResponse)
      .then((res) => {
        const { status, userLists } = res;
        if (status === 200) {
          dispatch({ type: LOAD_USER_LIST_END, payload: userLists });
        } else {
          // initialize error message
        }
      })
      .catch(handleCatch);
  };
};
