//React
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createUserList,
  resetUserList,
  editUserList,
  getUserListFromId,
} from "actions/userList";
// Tools
import Select from "react-select";
import { validateEmail } from "../../utils/mailValidation";
import styled from "styled-components";
import theme from "theme";
import Icon from "utils/icon";
import { decryptData } from "utils/Encryption";
//i18n
import { FormattedMessage } from "react-intl";
import messages from "../messages";
//Components
import SidePageLayout, { Input, customStyles, Info } from "../Layout/SidePage";
import Button from "components/Button/Button";
import Spinner from "components/Spinner/Spinner";
//Style
import { Container } from "../style";

const MailSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px 0px;
  border: 1px solid ${theme.ui.blue};
  border-radius: 5px;
  padding: 6px 10px;

  & > span {
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

const RightDiv = styled.div`
  display: flex;
  align-items: center;
`;

const IconStyled = styled(Icon)`
  margin-left: 8px;
`;

const NotValidate = styled.span`
  color: ${theme.red};
`

const UserListEdit = ({ close, userListId }) => {
  // Local state
  const [title, setTitle] = useState("");
  const [desc, setDesc] = useState("");
  const [users, setUsers] = useState([]);
  const [admins, setAdmins] = useState([]);
  const [userToAdd, setUserToAdd] = useState("");
  const [adminToAdd, setAdminToAdd] = useState("");
  const [secureGroups, setSecureGroups] = useState([]);

  const { userListSelected, fetchingUserList } = useSelector(
    (state) => state.userList
  );
  let { userFront } = useSelector((state) => state.user);
  if(!userFront.id) {
    userFront = typeof window !== 'undefined' && JSON.parse(localStorage?.getItem("userFront"));
  }
  // Get userFront data
  const userData = decryptData(userFront?.data);
  // Bind Actions
  const dispatch = useDispatch();
  const create = (title, desc, users, admins, secureGroups) => dispatch(createUserList(title, desc, users, admins, secureGroups));
  const edit = (id, title, users, desc, admins, secureGroups) => dispatch(editUserList(id, title, users, desc, admins, secureGroups));
  const resetUserListAction = () => dispatch(resetUserList());
  const get = (userListId) => dispatch(getUserListFromId(userListId));

  useEffect(() => {
    if (userListId) {
      get(userListId);
    }
  }, []);

  useEffect(() => {
    if (Object.keys(userListSelected).length) {
      setTitle(userListSelected.name);
      setDesc(userListSelected.description);
      setUsers(userListSelected.users);
      setAdmins(userListSelected.admins);
      setSecureGroups(userListSelected.secureGroups.map((secureGroupId) => {
        return { value: secureGroupId, label: userData?.secureGroups?.find((g)=>g.publicId === secureGroupId)?.name || <FormattedMessage {...messages.hidden_name} />};
      }));
    }
  }, [userListSelected]);

  const createUserListAction = () => {
    if (title) {
      create(title, desc, users, admins, secureGroups.map((sg)=>sg.value));
      reset();
    }
  };

  const editVoteAction = () => {
    if (userListSelected.id && title) {
      edit(userListSelected.id, title, users, desc, admins, secureGroups.map((sg)=>sg.value));
      reset();
    }
  };

  const reset = () => {
    resetUserListAction();
    setTitle("");
    setDesc("");
    setUsers([]);
    setAdmins([]);
    close();
  };

  const addUser = () => {
    // check with Regex if userToAdd is a valid email
    if (userToAdd && validateEmail(userToAdd)) {
      let updatedValue = {};
      updatedValue = {mail: userToAdd};
      users.push(updatedValue);
      setUserToAdd("");
    }
  };

  const removeUser = (index) => {
    const newUsers = [...users];
    newUsers.splice(index, 1);
    setUsers(newUsers);
  };

  return (
    <SidePageLayout
      title={userListId &&  <FormattedMessage {...messages.userlist_edit} /> || <FormattedMessage {...messages.userlist_title} />}
      buttonMessage={userListId && <FormattedMessage {...messages.userlist_editlist} /> || <FormattedMessage {...messages.userlist_add} />}
      onClose={reset}
      onValid={userListSelected?.id ? editVoteAction : createUserListAction}
    >
      <Container>
        {userListId && fetchingUserList ? (
          <Spinner>
            <Icon icon="LoadingHalf" />
          </Spinner>
        ) : (
          <>
            <FormattedMessage {...messages.title}>
              {(msg) => (
                <Input
                  type="text"
                  title={msg}
                  placeholder={msg}
                  onChange={(e) => setTitle(e.target.value)}
                  value={title}
                />
              )}
            </FormattedMessage>
            <FormattedMessage {...messages.description}>
              {(msg) => (
                <Input
                  type="text"
                  title={msg}
                  placeholder={msg}
                  onChange={(e) => setDesc(e.target.value)}
                  value={desc}
                />
              )}
            </FormattedMessage>
            <FormattedMessage {...messages.users}>
              {(msg) => (
                <Input
                  type="text"
                  title={msg}
                  placeholder={msg}
                  onChange={(e) => setUserToAdd(e.target.value)}
                  value={userToAdd}
                />
              )}
            </FormattedMessage>
            <Button type="button" action={() => addUser()}>
              <FormattedMessage {...messages.add} />
            </Button>
            {users?.map((user, index) => (
              <MailSection key={index}>
                <span>{user.mail}
                {
                  !user.isValidate && <NotValidate>*</NotValidate>
                }
                </span>
                <RightDiv>
                  <IconStyled
                    icon="Cross"
                    size="xs"
                    color="red"
                    onClick={() => removeUser(index)}
                  />
                </RightDiv>
              </MailSection>
            ))}
            <hr/>
            {
              userData?.secureGroups?.length &&
                <React.Fragment>
                  <div>
                    <FormattedMessage {...messages.more_parameter} />
                  </div>
                  <FormattedMessage {...messages.secure_groups} >
                  {(msg)=>
                    <Select
                      isMulti
                      name="secureGroups"
                      styles={customStyles}
                      options={
                        userData.secureGroups.map((g)=>{
                          return { value: g.publicId, label: g.name };
                        })
                      }
                      onChange={setSecureGroups}
                      components={{ IndicatorSeparator:() => null }}
                      placeholder={msg}
                      value={secureGroups}
                    />
                  }
                  </FormattedMessage>
                  <Info>
                    <FormattedMessage {...messages.secure_groups_desc} />
                  </Info>
                </React.Fragment>
            }
          </>
        )}
        {
          userListSelected?.users?.length &&         
          <Info>
            <FormattedMessage {...messages.user_not_validate} />
          </Info>
        }
      </Container>
    </SidePageLayout>
  );
};

export default UserListEdit;
